body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292929 !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-style {
  position: fixed;
  
}

.nav_icons {
  color:#bdc3c7;
  font-size:20px;
  margin-left: 15px;
}

.user_greeting {
  color:#8b92a2;
  font-weight: bold;
}
.sidenav---sidenav---_2tBP {
  position: fixed !important;
  background-color: #191718 !important;
  box-shadow: 7px 0 5px -2px rgb(3, 3, 3);
  height: 100vh;
  
  
}



/* .sidenav_height {
  height: calc(100vh - 50px);
} */

.sidenav---navtext---1AE_f
{
  color:#536070 !important;
}

.user_column {
  text-align: right !important;
}


.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  background-color: #536070 !important;
}

.top_user {
color:#536070 !important;
font-size:14px;
font-weight: bold;
}

.user_icon {
  width:25px !important;
  margin-left: 10px;
}

.notification_icon {
  width:25px;
  margin-left: 10px;
  /* padding: 15px;
  box-shadow: 0px 3px 3px 3px rgb(211, 211, 211);
  border-radius: 10px; */
  background-color: #e6ebef;
  
  

}


.notification_icon:hover {
  width:25px;
  margin-left: 10px;
  /* padding: 15px;
  box-shadow: 0px 3px 3px 3px rgb(211, 211, 211);
  border-radius: 10px; */
  background-color: #e6ebef;
  animation: shake 0.5s;

  
  animation-iteration-count: infinite;
  

}

.memosight-Icons {
  width:30px;
  margin-left: 15px;
  cursor: pointer;
}



@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.footer {
  
  bottom:0;
  width:100%;
  height:80px;   /* Height of the footer */
  
}

.footer_logo {
  text-align: right;
}


hr.footer_hr {
  height: 10px;
  background: #191718;
  box-shadow: inset 1px 1px 9px #0b0a0b,
              inset -1px -1px 9px #272425;
}

.sideAudioWrapper {
  
  
  padding: 10px 10px 10px 10px ;
  /*box-shadow: inset 5px 5px 5px 0 #e9f0b7, inset -5px -5px 5px #7b6c51;*/
  margin-top:43px;
  
  border-radius: 10px;
background: linear-gradient(145deg, #2c2c2c, #252525);
box-shadow:  5px 5px 10px #121212,
             -5px -5px 10px #404040;
 
}

.latestAudioWrapper {
  
  
  padding: 10px 10px 10px 10px ;
  /*box-shadow: inset 5px 5px 5px 0 #e9f0b7, inset -5px -5px 5px #7b6c51;*/
  
  border-radius: 10px;
  background: linear-gradient(145deg, #2c2c2c, #252525);
  box-shadow:  5px 5px 10px #121212,
               -5px -5px 10px #404040;
}

.fw-normal {
  color:#fff;
}


.sideAudioFullScreen {
 margin-top:35px;
}
.audio-title {
  color:#8b92a2 !important;
  
}

.audio-title2 {
  color:#8b92a2 !important;
  font-size:17px;
  
}

.audio-date {
  color:#8b92a2 !important;
  font-size:12px !important;
}

.date-align {
  text-align: right;
}


.thumbsup {
  color:#51dd00;
  font-size:25px ;
      margin-left: 10px;
}

.icon_button_style {
  background:none !important;
  border:none !important;
  padding:0px 0px 0px 0px;
  
}
.icon_button_style:focus {
  outline: none;
}

audio {
  width:100% !important;
  overflow: hidden;
  background:  #292929;
}

audio:focus {
  outline: none;
  color: #000;
}


     audio::-webkit-media-controls-panel {
     background:  #292929;
     overflow: hidden;
     width: 100% !important;
     border:none !important;
     border-radius: 0px !important;
     
     }

     audio::-webkit-media-controls-play-button
     {
     background-color:#292929;
    color:#fff !important;
    overflow: hidden;
     margin-right: 15px;
     padding:25px;
     border-radius: 10px;

box-shadow:  4px 4px 7px #121212,
             -4px -4px 7px #404040;
     }


     audio::-webkit-media-controls-overlay-enclosure {
      display: none;
  }


     audio::-webkit-media-controls-current-time-display {
       visibility: hidden;
       display: none;
     }

     audio::-webkit-media-controls-time-remaining-display {
      visibility: hidden;
      display: none;
    }


    .insight-icon {
      margin-top:10px;
      width:80%;
    }

     .LatestMemoStyle {
      /* border-radius: 10px; */
      padding: 20px 20px 20px 20px ;
      /*box-shadow: inset 5px 5px 5px 0 #e9f0b7, inset -5px -5px 5px #7b6c51;*/
      border-radius: 10px;
background: #292929;
box-shadow: inset 5px 5px 10px #0b0a0b,
            inset -5px -5px 10px #272425;
      /* box-shadow: 15px 5px 25px 10px rgb(211, 211, 211); */
     }

     .latest-memo-wrapper {
       margin-top:30px;
     }

     .latest-memo-title {
       font-size:23px;
       color:#8b92a2;
       text-align: center;
     }

     .latest-memo-date {
      font-size:12px;
      color:#96b6c3;
      text-align: right;
      font-style: normal;
     }

     .latest-memo-title2 {
      font-size:24px;
      color:#f1f1f1;
      text-align: center;
      font-weight: 500px;
      margin-bottom: 30px;
    }

    

    .insightThumb {
      width:130px;
    }

    .leftArrow {
      text-align: right;
      padding-top:15%;
    }

    .rightArrow {
      text-align: left;
      padding-top:15%;
    }

    .memoIconsWrapper {
      text-align: right;
    }

    .memoIcons {
      font-size:25px ;
      margin-left: 10px;
      color:#807f7f;

    }

    .fullscreenIcon {
      font-size:25px;
      color:#807f7f;
    }


    .insight-memo-title {
      font-size:25px;
      color:#f1f1f1;
      
      font-weight: bold;
    }


    .insightIconsWrapper {
      text-align: right;
      
    }

    .insightIcons {
      font-size:25px ;
      margin-left: 10px;
      color:#807f7f;
    }

    .memoWarehouse {
      text-align: right;
    }

    .sidenav---navtext---1AE_f>a {
      color:#f1f1f1 !important;
      text-decoration: none;
    }

    .sidenav---sidenav-navitem---uwIJ- {
      margin-bottom: 25px;
    }

    .sidebarIcon {
      padding:5px 5px 5px 5px;
      width:40px;
      height: auto;
      border-radius: 10px;
background: #191718;
box-shadow: inset 4px 4px 9px #0f0e0e,
            inset -4px -4px 9px #232022;
    }


    .btn_insight {
      background-color: transparent;
      border: none;
    }

    .main-container {
      padding-left: 100px;
      padding-right:30px;
      margin-top:15px;
    }

    .copyright_desktop {
      text-align: left;
      font-size: 14px;
      display:block;
      color:#fff;
    }

    .copyright_mobile {
      display: none;
      color:#fff;
    }


    .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
      line-height: 40px !important;
    }

    .mobileInsightIconsWrapper {
      display: none;
      
    }

    .audio-title-mobile {
      color:#f1f1f1 !important;
      font-size:20px;
      
    }


    .deck-cart-mobile {
      display: none;
    }


    .bottom_div {
      margin-right: auto !important;
      margin-left:16% !important;
      background-color: #191718;
      box-shadow: 7px 0 5px -2px rgb(3, 3, 3);
      color:#fff;
        position:fixed;
        bottom:10px;
        width:60%;
        z-index:100;
        padding:10px 10px 10px 10px;
        border-radius:10px;
        display:block;
       
    }
    
    .bottom-div-min {
      margin-right: auto !important;
      margin-left:70% !important;
      background-color: #191718;
      box-shadow: 7px 0 5px -2px rgb(3, 3, 3);
      color:#fff;
        position:fixed;
        bottom:10px;
        width:60px;
        z-index:100;
        padding:10px 10px 10px 10px;
        border-radius:10px;
        display:block;
       
    }
    
    .close_btn {
      text-align:right;
    }

    .clientLogo {
      margin-left:0px;
    }
    


    @media only screen and (max-width:780px) {
      .sidenav---sidenav---_2tBP {
        display: none !important;
      }

      .clientLogo {
        margin-left:30px;
      }

      .user_greeting {
        display: none !important;
      }

      .main-container {
        padding-left: 20px;
        padding-right:20px;
        margin-top:5px;
      }

      .insight-memo-title {
        font-size:20px;
        color:#f1f1f1;
        text-align: center;
        font-weight: bold;
      }

      .insightIconsWrapper {
        display: none;
        
      }

      .mobileInsightIconsWrapper {
        display: block;
        
      }

      .memoIconsWrapper {
        text-align: center;
      }

      .insight-icon {
        margin-top:20px;
        width:200%;
      }

      .audio-title2 {
        color:#f1f1f1 !important;
        font-size:13px;
        
      }

      .audio-title-mobile {
        color:#f1f1f1 !important;
        font-size:22px;
        
      }

      .audio-date {
        color:#f1f1f1 !important;
        font-size:9px !important;
      }

      .footer_logo {
        text-align: center;
      }

      .copyright_desktop {
        display: none;
        color:#fff;

      }
  
      .copyright_mobile {
        text-align: center;
        font-size: 12px;
        display: block;
        margin-top:15px;
        color:#fff;
      }

      .deck-cart-mobile {
        position: fixed;
        bottom:20px;
        display: block;
        background-color: #191718;
        box-shadow: 7px 0 5px -2px rgb(3, 3, 3);
        color:#fff;
        padding: 10px 10px 10px 10px;
        border-radius:10px;
      }

      .bottom_div {
        display:none;
      }

      .bottom_div_min {
        display: none;
      }

      .sideAudioFullScreen {
        margin-top:25px;
       }
  
    }

/* sign in */
.login-container {
  margin-top:100px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 5px;
  color: #536070;
 
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: #e6ebef !important;
    box-shadow: 7px 0 5px -2px rgb(211 211 211);

}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.mobileNav {
  color:#f1f1f1;
  font-size: 12px;
  text-decoration: none;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width:100%;
}

/* General sidebar styles */
.bm-menu {
  background-color: #191718 !important;
    /* box-shadow: 7px 0 5px -2px rgb(211 211 211); */
  padding: 0px 0px 0px 0px;

  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.plus-align {
  text-align: right;
}

.btn-plus-mSight {
  color:#3ff5e5;
  border-radius: 50%;
background: #191718;
box-shadow:  3px 3px 5px #0a090a,
             -3px -3px 5px #282526;
  border:none;             
}

.mCard_count {
font-size:12px;
color: #f1f1f1;
}

/*Mcard .. */

.profile-card-2 {
  max-width: 200px;
  height:250px;
  background-color: #FFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
}

.profile-card-2 img {
  transition: all linear 0.25s;
}

.profile-card-2 .profile-name {
  position: absolute;
  left: 10px;
  bottom: 25px;
  font-size: 15px;
  color: #FFF;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  transition: all linear 0.25s;
  text-shadow: 2px 2px #333;
}

.profile-card-2 .profile-icons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #FFF;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-username {
  position: absolute;
  bottom: 7px;
  left: 10px;
  color: #FFF;
  font-size: 9px;
  transition: all linear 0.25s;
  text-shadow: 2px 2px #333;
}

.profile-card-2 .profile-icons .fa {
  margin: 5px;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
}

.profile-card-2:hover .profile-name {
  bottom: 30px;
}

.profile-card-2:hover .profile-username {
  bottom: 20px;
}

.profile-card-2:hover .profile-icons {
  right: 20px;
}


.dropdown_style {
  border-radius: 10px;
  background: #292929;
  box-shadow: inset 4px 4px 7px #1d1d1d,
              inset -4px -4px 7px #353535;
  color:#fff;
  border:none; 
  background: url("./assets/down_arrow.png") no-repeat right ;
    -webkit-appearance: none;
    background-position-x: 150px;           
  
}

.dropdown_style:focus {
  border-radius: 10px;
  background: #292929;
  box-shadow: inset 4px 4px 7px #1d1d1d,
              inset -4px -4px 7px #353535;
  color:#fff;
  border:none;    
  
  
}

.generate_deck {
  
  border-radius: 16px;
background: #56aaf6;
/* box-shadow:  -5px -5px 11px #19625c,
             5px 5px 11px #65ffff; */
             border:none;
}


.modal-content {
  background-color: #191718;
      box-shadow: 7px 0 5px -2px rgb(3, 3, 3);
      color:#fff;
}

.btn-close {
  color:#333 !important;
  background-color:#fff;
}

.modal-header {
  border-bottom: 0px !important;
}

.modal-footer {
  border-top: 0px !important;
}

/* .form-control {
  border-radius: 10px;
  background: #292929;
  box-shadow: inset 4px 4px 7px #1d1d1d,
              inset -4px -4px 7px #353535;
  color:#fff;
  border:none; 
} */


/*Audio Player */

.player {

  
 
  background-color:transparent;
 
}


.player .controls {
  width:100%;
  margin-top:10px;
}
.player .player__button {
  
    background-color:#292929;
   color:#fff !important;
   overflow: hidden;
   width:100%;
    border-radius: 10px;

box-shadow:  4px 4px 7px #121212,
            -4px -4px 7px #404040;
}
.player .player__button:focus {
  outline: none;
}
.player .player__button:hover {
  cursor: pointer;
}
.player .player__button:hover svg {
  color: #56aaf6;
}
.player .player__button svg {
  font-size: 4em;
  color: white;
}
.player .bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
}
.player .bar .bar__time {
  color: white;
  font-size: 16px;
}
.player .bar .bar__progress {
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  margin-top:20px;
  height: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: #333 !important;
  
}
.player .bar .bar__progress .bar__progress__knob {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1.5px solid rgb(85, 82, 82);
  border-radius: 50%;
  background-color: #000;
}
@media screen and (max-width: 800px) {
  .player {
    flex-direction: column;
 }
  .player .controls {
    width: 100%;
    margin-top: 10px;
 }
  .player .bar {
    width: 100%;
    margin-left:20px;
 }
}
@media screen and (max-width: 500px) {
  .player .song .song__title {
    font-size: 2.5em;
 }
  .player .song .song__artist {
    font-size: 0.8em;
 }
}


.btn-play-btn {
  background-color:#292929;
   color:#fff !important;
   overflow: hidden;
   padding:10px 15px 10px 15px;
    border-radius: 10px;

box-shadow:  4px 4px 7px #121212,
            -4px -4px 7px #404040;
}
/*Audio Player End */

.dark_btn {
  background-color:#292929;
   color:#fff !important;
   overflow: hidden;
   padding:10px 15px 10px 15px;
    border-radius: 10px;

box-shadow:  4px 4px 7px #121212,
            -4px -4px 7px #404040;
            margin:15px 15px 15px 15px;
}

.profile_wrapper {
  padding: 10px 10px 10px 10px ;
  /*box-shadow: inset 5px 5px 5px 0 #e9f0b7, inset -5px -5px 5px #7b6c51;*/
 
  
  border-radius: 10px;
background: linear-gradient(145deg, #2c2c2c, #252525);
box-shadow:  5px 5px 10px #121212,
             -5px -5px 10px #404040;
}

.avatar {
  width:130px;
}

.change_pic {
  color:#ccc;
}

label {
  font-size: 12px;
  color:#999;
}

.profileInput {
  
    border-radius: 10px;
    background: #292929;
    box-shadow: inset 4px 4px 7px #1d1d1d,
                inset -4px -4px 7px #353535;
    color:#fff;
    border:none; 
    width:100% !important;
    
    
  
}

.form-check-input {
  cursor: pointer;
}

.reset_password_wrapper {
  text-align: right;
}

h4 {
  color: #ccc;
}

p {
  color:#fff;
}

.form-check-input:checked {
  background-color: #08f800 !important;
    border-color: #05db3b !important;
}

.update_button {
  text-align: right !important; 
}

.logoutAlign {
  text-align: left !important;
}

.resetAlign {
  text-align: right !important; 
  right:0;
}


.profileBtn {
  background:transparent !important;
  border:none !important;
}




@media only screen and (max-width:780px) {

  .update_button {
    text-align: center !important; 
  }
  
  .logoutAlign {
    text-align: center !important;
  }
  
  .resetAlign {
    text-align: center !important; 
  }

  

}


.logout {
  width:20px;
}

.reset {
  width:20px;
}

.upload {
  text-align: right !important;
}

.avatar-wrapper {
  padding: 10px 20px 20px 20px;
}

.forgot-password {
  color:#56aaf6;
  text-decoration: none;
}

.login_copyright {
  
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
  
}



.dropdown_style2 {
  border-radius: 10px;
  background: #292929;
  box-shadow: inset 4px 4px 7px #1d1d1d,
              inset -4px -4px 7px #353535;
  color:#fff;
  border:none; 
  background: url("./assets/down_arrow.png") no-repeat right ;
    -webkit-appearance: none;
    background-position-x: 95%;           
  
}

.dropdown_style2:focus {
  border-radius: 10px;
  background: #292929;
  box-shadow: inset 4px 4px 7px #1d1d1d,
              inset -4px -4px 7px #353535;
  color:#fff;
  border:none;  
            
  
  
}

.justify {
  text-align: justify;
}


.accordion-button:not(.collapsed) {
  color:#30989b !important;
  background:none !important ;
  font-weight: bold !important;
}

.accordion-button {
  border:none !important;
  color:#30989b !important;
  background:none !important ;
  font-weight: bold !important;
} 

.accordion-button::after {
  color:#30989b !important;
  
}

.accordion-collapse {
  border:none !important;
  color:#fff !important;
}


ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #30989b;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.btn-load {
  background-color: transparent !important;
  border:1px solid #919191 !important;
  color:#919191 !important;
  border-radius: 0px !important;

}